import React, { useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import {
  NativeBaseProvider,
  extendTheme,
  theme as nbTheme,
  Text,
  Box,
  Spinner,
  HStack,
  Button,
} from "native-base";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import ListingScreen from "./screens/ListingScreen";
import ProductScreen from "./screens/ProductScreen";
import UserListingScreen from "./screens/UserPage/UserListingScreen";
import UserFavoritesScreen from "./screens/UserPage/UserFavoritesScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Platform } from "react-native";
import * as Linking from "expo-linking";

import CreateScreen from "./screens/CreateProduct/index";
import { useUser } from "./services/auth";
import { ADMIN_UID, isWeb, ROUTES } from "./constants";
import { AdminScreen } from "./screens/AdminScreen";
import UserProfileScreen from "./screens/UserProfile/UserProfileScreen";
import Footer from "./screens/ListingScreen/components/Footer";
//import * as Sentry from "sentry-expo";
import { isIOS } from "./utils";

//Sentry.init({
//  dsn: "https://99b1da9d41bc49b08d8404bd2d954dd9@o4504280745443328.ingest.sentry.io/4504280747016192",
//  enableInExpoDevelopment: true,
//  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
//});

if (Platform.OS === "ios") {
  // Polyfills required to use Intl with Hermes engine
  require("@formatjs/intl-getcanonicallocales/polyfill").default;
  require("@formatjs/intl-locale/polyfill").default;
  require("@formatjs/intl-pluralrules/polyfill").default;
  require("@formatjs/intl-pluralrules/locale-data/en").default;
  require("@formatjs/intl-numberformat/polyfill").default;
  require("@formatjs/intl-numberformat/locale-data/en").default;
  require("@formatjs/intl-datetimeformat/polyfill").default;
  require("@formatjs/intl-datetimeformat/locale-data/en").default;
  require("@formatjs/intl-datetimeformat/add-all-tz").default;
}

const theme = extendTheme({
  colors: {
    primary: {
      // ...nbTheme.colors.violet,
      // ...nbTheme.colors.red,
      // 500: "#7982bb",
      // 900: "#49527B",
      100: "#eeeff6",
      200: "#ccd0e5",
      300: "#aab0d4",
      400: "#8991c3",
      500: "#6771b2",
      600: "#4d5798",
      700: "#3c4476",
      800: "#2b3155",
      900: "#1a1d33",
    },
    accent: {
      500: "#acd7e0",
    },
  },
});

const config = {
  dependencies: {
    // For Expo projects (Bare or managed workflow)
    "linear-gradient": require("expo-linear-gradient").LinearGradient,
    // For non expo projects
    // 'linear-gradient': require('react-native-linear-gradient').default,
  },
};

// const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator();

function Routes() {
  const { user } = useUser();

  if (user === "init") {
    return (
      <Box
        bg="gray.100"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 100,
        }}
      >
        <Spinner
          accessibilityLabel="Loading posts"
          color="primary.600"
          size="lg"
        />
      </Box>
    );
  }

  return (
    <NavigationContainer>
      <Stack.Navigator
        // defaultStatus="closed"
        screenOptions={{ headerShown: false, swipeEnabled: false }}
      >
        <>
          {user.uid === ADMIN_UID && (
            <Stack.Screen name={ROUTES.ADMIN_SCREEN} component={AdminScreen} />
          )}
          <Stack.Screen name={ROUTES.LIST_SCREEN} component={ListingScreen} />
          <Stack.Screen
            name={ROUTES.PRODUCT_SCREEN}
            component={ProductScreen}
          />
          <Stack.Screen name={ROUTES.CREATE_SCREEN} component={CreateScreen} />
          <Stack.Screen
            name={ROUTES.MY_LISTINGS}
            component={UserListingScreen}
          />
          <Stack.Screen
            name={ROUTES.MY_FAVORITES}
            component={UserFavoritesScreen}
          />
          <Stack.Screen
            name={ROUTES.USER_PROFILE}
            component={UserProfileScreen}
          />
          <Stack.Screen name={ROUTES.SIGN_IN} component={SignIn} />
          <Stack.Screen name={ROUTES.SIGN_UP} component={SignUp} />
        </>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

function Banner() {
  return (
    <HStack
      backgroundColor={"gray.100"}
      alignItems={"center"}
      justifyContent="space-around"
      paddingY={2}
      paddingX={2}
    >
      <Text fontSize={"sm"}>Uygulama üzerinden bize ulaşın </Text>
      <Button
        onPress={() =>
          Linking.openURL(
            "https://apps.apple.com/tr/app/hepsicihaz/id6444583224"
          )
        }
        variant={"outline"}
      >
        Uygulamaya git
      </Button>
    </HStack>
  );
}
export default function App() {
  return (
    <NativeBaseProvider theme={theme} config={config}>
      {isIOS() && isWeb ? <Banner /> : null}
      <Routes />
    </NativeBaseProvider>
  );
}
