import {
  Button,
  Center,
  FormControl,
  Modal,
  Radio,
  TextArea,
  useToast,
} from "native-base";
import { memo, useState } from "react";
import { useUser } from "../services/auth";
import { useProduct } from "../services/product";
import { useRequest } from "../services/request";
import RNModal from "react-native-modal";
import { dims } from "../constants";

function CustomModal({ showModal, setShowModal }) {
  const { user } = useUser();
  const { createOtherRequest } = useRequest();
  const [description, setDescription] = useState("");
  const toast = useToast();
  const { infoService, setInfoService } = useProduct();

  async function onClickService(description) {
    await createOtherRequest({
      userId: user.uid,
      type: infoService.id,
      description: description,
    });
    setShowModal(false);
    setDescription("");
    setInfoService();
    toast.show({
      description: "Talebinizi aldık. En kısa sürede size dönüş yapacağız.",
    });
  }

  const title = infoService?.title;
  const body = `${infoService?.title} için tarafınıza ulaşılacaktır.`;

  return (
    <RNModal
      swipeDirection={["up", "left", "right", "down"]}
      isVisible={showModal}
      onBackdropPress={() => {
        setShowModal(false);
        setInfoService();
      }}
      deviceWidth={dims.width}
      deviceHeight={dims.height}
    >
      <Modal.Content>
        <Modal.CloseButton
          onPress={() => {
            setShowModal(false);
            setInfoService();
          }}
        />
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          {body}
          <FormControl w="100%" isRequired mt={4}>
            <FormControl.Label>İstek Detay</FormControl.Label>
            <TextArea
              placeholder="Açıklama giriniz"
              value={description}
              onChangeText={(text) => setDescription(text)}
            />
          </FormControl>
        </Modal.Body>

        <Modal.Footer justifyContent="left">
          <Button
            onPress={() => {
              if (description.length <= 0) {
                return toast.show({
                  description: "Lütfen istek detayınızı giriniz.",
                });
              }
              onClickService(description);
            }}
          >
            Gönder
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </RNModal>
  );
}

export default memo(CustomModal);
