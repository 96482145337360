import { Box } from "native-base";
import { useEffect, useState } from "react";
import { useCategory } from "../../../services/categories";
import { useProduct } from "../../../services/product";
import Categories from "./Categories";
import SearchBar from "./SearchBar";

export default function ListMenu({ navigate }) {
  const [showModal, setShowModal] = useState(false);
  const { categories = [] } = useCategory();
  const {
    products,
    selectedService,
    setSelectedService,
    setFilteredProducts,
    filteredProducts,
    term,
    setTerm,
    infoService,
    setInfoService,
  } = useProduct();

  useEffect(() => {
    if (term === "") {
      setFilteredProducts(products);
    } else {
      searchProducts(term);
    }
  }, [term]);

  const searchProducts = (searchWith) => {
    const service = selectedService || "sale";
    const prods = products?.filter((p) => p.isApproved && !p.isDeleted && p.saleType === service);
    if (searchWith === "Tümü") {
      setFilteredProducts(prods);
      return;
    }
    let tempArray = prods.filter(
      (item) =>
        item.category.toLowerCase() === searchWith.toLowerCase() ||
        item.title.toLowerCase().search(searchWith.toLowerCase()) !== -1
    );
    setFilteredProducts(tempArray);
  };

  return (
    <>
      <Box
        w="100%"
        marginX="auto"
        bg="primary.500"
        mt="-1"
        borderColor={"primary.500"}
      >
        <SearchBar
          term={term}
          onTermChange={setTerm}
          onSubmit={() => {
            searchProducts(term);
          }}
        />
      </Box>

      <Box pb="2" px="1" w="100%" maxW={"600px"} marginX="auto">
        <Categories
          selected={term || "Tümü"}
          data={categories}
          onPressCategory={(category) => {
            requestAnimationFrame(() => {
              setTerm(category);
              searchProducts(category);
            });
          }}
        />
      </Box>
    </>
  );
}
