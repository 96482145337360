import React, { memo } from "react";
import { StyleSheet } from "react-native";
import { Box, Pressable, Text, Image, Badge } from "native-base";
import { truncate } from "../../../utils";

import { dims, isWeb } from "../../../constants";
import CachedImage from "react-native-expo-cached-image";

const FastImage = ({ uri, cacheKey, style, alt }) => {
  if (isWeb) {
    return <Image source={{ uri }} key={cacheKey} style={style} alt={alt} />;
  } else {
    return <CachedImage source={{ uri }} key={cacheKey} style={style} alt={alt} />;
  }
};

const ProductCard = memo(({ item, onPressCheck }) => {
  const photoUri = item.photos?.[0];
  if (!photoUri) return null;

  const date = new Date(item.createdAt);
  const formattedDate = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}`;
  return (
    <Box
      style={styles.container}
      overflow="hidden"
      borderRadius={"12px"}
    >
      <Pressable onPress={() => onPressCheck(item.id)}>
        <Box position="relative" height={350} width="100%">
          <FastImage
            uri={photoUri}
            cacheKey={photoUri + "-thumb"}
            alt={item.title}
            style={{
              height: "100%",
              minHeight: 350,
              resizeMode: "cover",
              width: Math.min(dims.width - 20, 580),
            }}
          />
          <Box
            padding="4"
            w="100%"
            h="40%"
            position="absolute"
            bottom={0}
            left={0}
            bg={{
              linearGradient: {
                colors: ["transparent", "black"],
                start: [0, 0],
                end: [0, 1],
              },
            }}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Box
              display={"flex"}
              width="100%"
              flexDirection="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Text
                color="coolGray.100"
                fontWeight={"bold"}
                fontSize="md"
                maxW={"90%"}
              >
                {truncate(item.title, 50)}
              </Text>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Badge marginTop={"2"}>
                <Text fontSize="sm">{item.category}</Text>
              </Badge>

              {item.displayPrice ? (
                <Text color="coolGray.100" fontSize="lg">
                  {new Intl.NumberFormat("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                    minimumFractionDigits: 0,
                  }).format(item.price)}
                </Text>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Pressable>
    </Box>
  );
});

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  imageStyle: {
    height: 350,
    minHeight: 350,
    width: "100%",
  },
  name: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    color: "#fff",
  },
  textOnImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    flex: 1,
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0.4,
    backgroundColor: "black",
    width: "100%",
    zIndex: -1,
  },
});

export default ProductCard;
