import { useEffect, useRef } from 'react';
import create from 'zustand';
import { auth, db } from '../firebaseDb';


const NO_USER = { uid: null, email: null, displayName: null, emailVerified: null }
export const useAuthStore = create((set) => ({
  authUser: 'init',
  setAuthUser: (authUser) => set(() => ({ authUser })),
  users: null,
  setUsers: (users) => set(() => ({ users })),
  userDetail: null,
  setUserDetail: (userDetail) => set(() => ({ userDetail })),
}));

export function useUser() {
  const listenerRef = useRef();

  const { authUser, setAuthUser, users, setUsers, userDetail, setUserDetail } = useAuthStore((state) => ({
    setAuthUser: state.setAuthUser,
    authUser: state.authUser,
    users: state.users,
    setUsers: state.setUsers,
    userDetail: state.userDetail,
    setUserDetail: state.setUserDetail,
  }));

  const authUserUid = authUser?.uid

  async function getUsers(force) {
    if (!force && users) {
      console.log('returning users from the cache')
      return users
    };
    console.log("refetching users...")
    const collection = db.collection("users");
    const arr = [];
    await collection.get().then((snap) => {
      snap.forEach((doc) => {
        const data = doc.data();
        arr.push({
          id: doc.id,
          ...data,
        });
      });
    });
    setUsers(arr);
    return arr;
  }

  useEffect(() => {
    if (listenerRef.current) return;
    listenerRef.current = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          emailVerified: user.emailVerified
        })
      } else {
        setAuthUser(NO_USER)
      }
    });
    return () => listenerRef.current();
  }, []);

  useEffect(() => {
    if (authUserUid) {
      getCurrentUserDetails();
    }
  }, [authUserUid]);

  async function createUser({ phone, email, name, lastName, pass }) {
    await auth
      .createUserWithEmailAndPassword(email, pass)
      .then(async (res) => {
        const userUid = res?.user?.uid
        const userEmail = res?.user?.email;
        const doc = db.doc(`users/${userEmail}`);

        await doc.set({
          email: email,
          phone: phone,
          uid: userUid,
          name: name,
          lastName: lastName
        })
      })
      .catch((error) => {
        console.log("error @createUser");
      });
  }

  async function getCurrentUserDetails() {
    if (userDetail) return userDetail;
    else if (users) return users.find(user => user.uid === authUser.uid)
    else if (!authUser.email) return null;
    else {
      const doc = db.doc(`users/${authUser.email}`);
      const snap = await doc.get();
      const user = snap.data();
      setUserDetail(user)
      return user
    }
  }

  async function logout() {
    setAuthUser(NO_USER)
    setUserDetail(null)
    setUsers(null)
    await auth.signOut();
  }

  async function deleteAccount(id) {
    try {
      const user = auth.currentUser
      const doc = db.doc(`users/${user.email}`);
      await doc.update({ deleted: true })
      await user.delete()
    } catch (error) {
      console.log("error @deleteAccount")
    }
  }

  return {
    user: authUser,
    users,
    userDetail,
    getUsers,
    createUser,
    logout,
    deleteAccount
  }
}