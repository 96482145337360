import React, { useState } from "react";
import {
  Button,
  Image,
  HStack,
  VStack,
  Text,
  Link,
  Icon,
  IconButton,
  useColorModeValue,
  Hidden,
  Center,
  StatusBar,
  Box,
  Stack,
} from "native-base";
import { AntDesign, Entypo } from "@expo/vector-icons";
import FloatingLabelInput from "./components/FloatingLabelInput";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { auth } from "../../firebaseDb";
import { object, string, ref } from "yup";
import { ROUTES } from "../../constants";
import { useUser } from "../../services/auth";

let userSchema = object({
  email: string()
    .email("Geçerli bir e-posta adresi giriniz.")
    .required("E-posta alanı zorunludur."),
  name: string().required("Ad alanı zorunludur."),
  lastName: string().required("Soyad alanı zorunludur."),
  pass: string().required("Şifre alanı zorunludur."),
  confirm_pass: string().oneOf([ref("pass"), null], "Şifreler eşleşmiyor."),
});

function SignUpForm({ props }) {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pass, setPass] = useState("");
  const [confirm_pass, setConfirmPass] = useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { createUser } = useUser();

  async function onSignUpPressHandler() {
    if( !(phone.match('^[0-9\b]+$')) ){
      setError("Lütfen geçerli bir telefon numarası giriniz.");
      return;
    }

    const valid = await userSchema
      .validate({
        email,
        name,
        lastName,
        pass,
        confirm_pass,
      })
      .catch((err) => {
        setError(err.message);
      });
    if (!valid) return;

    setLoading(true);
    await createUser({
      phone,
      email,
      name,
      lastName,
      pass,
    })
      .then(() => {
        setError();
        setPhone("");
        setEmail("");
        setName("");
        setLastName("");
        setPass("");
        setConfirmPass("");
        setLoading(false);
        props.navigation.navigate(ROUTES.LIST_SCREEN);
      })
      .catch((error) => {
        console.log("error @onSignUpPressHandler");
        setError(error.message);
        setLoading(false);
      });
  }

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ flex: 1 }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        bg="white"
        justifyContent="space-between"
        space="3"
        borderRadius={"2xl"}
        w="100%"
        maxW={"600px"}
        marginX="auto"
      >
        <VStack space="7">
          <Hidden till="md">
            <Text fontSize="md" fontWeight="normal">
              Devam etmek için üye olun
            </Text>
          </Hidden>
          <VStack>
            <VStack space="6">
              <VStack space="4">
                <FloatingLabelInput
                  isRequired
                  label="Telefon Numarası"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={phone}
                  onChangeText={(txt) => setPhone(txt)}
                  borderColor="#c9c9c9"
                  h="35px"
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                />
                <FloatingLabelInput
                  isRequired
                  label="E-posta"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={email}
                  onChangeText={(txt) => setEmail(txt)}
                  borderColor="#c9c9c9"
                  h="35px"
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                />
                <FloatingLabelInput
                  isRequired
                  label="Ad"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={name}
                  onChangeText={(txt) => setName(txt)}
                  borderColor="#c9c9c9"
                  h="35px"
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                />

                <FloatingLabelInput
                  isRequired
                  label="Soyad"
                  h="35px"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={lastName}
                  onChangeText={(txt) => setLastName(txt)}
                  borderColor="#c9c9c9"
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                />

                <FloatingLabelInput
                  isRequired
                  h="35px"
                  type={showPass ? "" : "password"}
                  label="Şifre"
                  borderRadius="4"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderColor="#c9c9c9"
                  defaultValue={pass}
                  onChangeText={(txt) => setPass(txt)}
                  InputRightElement={
                    <IconButton
                      variant="unstyled"
                      icon={
                        <Icon
                          size="4"
                          color="coolGray.400"
                          as={Entypo}
                          name={showPass ? "eye-with-line" : "eye"}
                        />
                      }
                      onPress={() => {
                        setShowPass(!showPass);
                      }}
                    />
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                    borderColor: "coolGray.300",
                  }}
                />

                <FloatingLabelInput
                  isRequired
                  type={showConfirmPass ? "" : "password"}
                  label="Şifre Tekrarı"
                  borderRadius="4"
                  labelColor="#9ca3af"
                  h="35px"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  defaultValue={confirm_pass}
                  onChangeText={(txt) => setConfirmPass(txt)}
                  InputRightElement={
                    <IconButton
                      variant="unstyled"
                      icon={
                        <Icon
                          size="4"
                          color="coolGray.400"
                          as={Entypo}
                          name={showConfirmPass ? "eye-with-line" : "eye"}
                        />
                      }
                      onPress={() => {
                        setShowConfirmPass(!showConfirmPass);
                      }}
                    />
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  borderColor="#c9c9c9"
                />
              </VStack>

              {error && (
                <Text fontSize="lg" color="red.600">
                  {error}
                </Text>
              )}

              <Button
                size="md"
                borderRadius="4"
                _text={{
                  fontSize: "sm",
                  fontWeight: "medium",
                }}
                isLoading={loading}
                bg="primary.500"
                onPress={onSignUpPressHandler}
              >
                ÜYE OL
              </Button>

              <Text fontSize="sm" color="coolGray.500" textAlign="center">
                Devam ederek bu şartları kabul etmiş olursunuz:{" "}
                <Link
                  href="https://hepsicihaz.com/aydinlatma-metni"
                  isExternal
                  _text={{
                    color: "blue.400",
                  }}
                  mt={-0.5}
                  _web={{ mb: -2 }}
                >
                  Aydınlatma Metni
                </Link>
              </Text>

              <VStack alignItems="center" justifyContent="center">
                <HStack
                  mb="2"
                  space="1"
                  alignItems="center"
                  justifyContent="center"
                  mt="auto"
                >
                  <Text fontSize="sm" color="coolGray.900">
                    Hesabınız var mı?
                  </Text>
                  <Button
                    variant="ghost"
                    _text={{
                      fontSize: "sm",
                      fontWeight: "bold",
                      color: "primary.500",
                    }}
                    onPress={() => {
                      props.navigation.navigate(ROUTES.SIGN_IN);
                    }}
                  >
                    Giriş Yap
                  </Button>
                </HStack>
                <Button
                  variant="ghost"
                  onPress={() => props.navigation.navigate(ROUTES.LIST_SCREEN)}
                  _text={{
                    fontSize: "sm",
                    fontWeight: "bold",
                    color: "primary.500",
                  }}
                >
                  Ürünleri incelemeye devam et
                </Button>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </KeyboardAwareScrollView>
  );
}

export default function SignUp(props) {
  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box safeAreaTop bg="primary.500" />
      <Center my="auto" bg="primary.500" flex="1">
        <Stack flexDirection="column" w="100%" flex="1">
          <VStack
            px="4"
            mt="4"
            mb="5"
            space="9"
            w="100%"
            maxW={"600px"}
            marginX="auto"
          >
            <VStack space="2">
              <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                Hoşgeldiniz
              </Text>
            </VStack>
          </VStack>

          <SignUpForm props={props} />
        </Stack>
      </Center>
    </>
  );
}
