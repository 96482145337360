import { HStack, Icon, Text } from "native-base";
import { AntDesign } from "@expo/vector-icons";

export default function NotFound() {
  return (
    <HStack
      space={2}
      style={{
        backgroundColor: "#fff",
        height: 70,
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon
        size="4"
        as={AntDesign}
        name="exclamationcircleo"
        // color="coolGray.50"
      />
      <Text>Gösterilecek ürün bulunamadı</Text>
    </HStack>
  );
}
