import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Icon,
  Text,
  Image,
  Button,
  FormControl,
  TextArea,
  useToast,
  AlertDialog,
} from "native-base";
import { dims, isWeb, ROUTES } from "../../constants";
import { Header } from "../../components/Header";
import { useRequest } from "../../services/request";
import { useUser } from "../../services/auth";
import { AntDesign } from "@expo/vector-icons";
import userImage from "../../assets/user.png";
import { Image as RNImage, Platform } from "react-native";
import DismissKeyboard from "../../components/DismissKeyboard";

const UserProfileScreen = ({ navigation }) => {
  const [description, setDescription] = useState("");
  const { user, userDetail, logout, deleteAccount } = useUser();
  const { createOtherRequest } = useRequest();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toast = useToast();
  const [accountDeleteIsOpen, setAccountDeleteIsOpen] = useState(false);
  const cancelRef = useRef(null);

  async function handleOnClickService() {
    setLoading(true);
    await createOtherRequest({
      userId: user.uid,
      type: "feedback",
      description: description,
    });
    setLoading(false);
    setDescription("");
    toast.show({
      description: "Talebinizi aldık. En kısa sürede size dönüş yapacağız.",
    });
  }

  function onAccountDeleteClose() {
    setAccountDeleteIsOpen(false);
  }

  async function onProceed() {
    setAccountDeleteIsOpen(false);
    await deleteAccount(user.uid);
    await logout();
    toast.show({
      description: "Hesabınız başarıyla silindi.",
    });
    navigation.navigate(ROUTES.LIST_SCREEN);
  }

  function getImageUri() {
    if (isWeb) {
      return userImage;
    } else {
      return RNImage.resolveAssetSource(userImage).uri;
    }
  }
  return (
    <>
      <Header
        title="Hesabım"
        backButtonPressed={() => navigation.navigate(ROUTES.LIST_SCREEN)}
        navigate={navigation.navigate}
      />

      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={accountDeleteIsOpen}
        onClose={onAccountDeleteClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Hesabı Sil</AlertDialog.Header>
          <AlertDialog.Body>
            Hesabınızı sildiğinizde tüm verileriniz silinecektir ve geri
            alınamayacaktır. Emin misiniz?
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onAccountDeleteClose}
                ref={cancelRef}
              >
                İptal
              </Button>
              <Button colorScheme="danger" onPress={onProceed}>
                Sil
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>

      <DismissKeyboard>
        <VStack
          my="auto"
          flex="1"
          w="100%"
          maxW={"600px"}
          marginX="auto"
          space={4}
          alignItems="center"
          bg="white"
          // h={"calc(100% - 76px)"}
          h={dims.height - 120}
        >
          <Image source={{ uri: getImageUri() }} alt="User" size={"lg"} />
          <VStack space={4}>
            {userDetail?.name ? (
              <HStack space={2}>
                <Icon
                  as={AntDesign}
                  name="user"
                  size="sm"
                  color="primary.500"
                />
                <Text fontSize="sm" fontWeight="bold">
                  {userDetail.name} {userDetail?.lastName}
                </Text>
              </HStack>
            ) : null}

            {userDetail?.email ? (
              <HStack space={2}>
                <Icon
                  as={AntDesign}
                  name="mail"
                  size="sm"
                  color="primary.500"
                />
                <Text fontSize="sm" fontWeight="bold">
                  {userDetail.email}
                </Text>
              </HStack>
            ) : null}

            {userDetail?.phone ? (
              <HStack space={2}>
                <Icon
                  as={AntDesign}
                  name="phone"
                  size="sm"
                  color="primary.500"
                />
                <Text fontSize="sm" fontWeight="bold">
                  {userDetail.phone}
                </Text>
              </HStack>
            ) : null}
          </VStack>

          <VStack space={4} w="80%" paddingY={4}>
            <FormControl w="100%" mt={2}>
              <FormControl.Label>Geri Bildirim Formu</FormControl.Label>
              <TextArea
                placeholder="Açıklama giriniz"
                value={description}
                onChangeText={(text) => setDescription(text)}
              />
            </FormControl>
            <Button
              leftIcon={<Icon as={AntDesign} name="mail" size="md" color="white" />}
              size={"md"}
              onPress={handleOnClickService}
            >
              Gönder
            </Button>
          </VStack>

          <VStack
            w="100%"
            space={1}
            alignItems="center"
            style={{
              alignItems: "flex-end",
              alignSelf: "stretch",
              justifyContent: "center",
            }}
          >
            <HStack
              alignItems="center"
              space={1}
              style={{
                alignItems: "flex-end",
                alignSelf: "stretch",
                justifyContent: "center",
              }}
            >
              <Button
                leftIcon={<Icon as={AntDesign} name="link" size="md" />}
                h="40px"
                w="40%"
                size={"md"}
                variant="outline"
                onPress={() => navigation.navigate(ROUTES.MY_LISTINGS)}
              >
                Ürünlerim
              </Button>
              <Button
                leftIcon={<Icon as={AntDesign} name="heart" size="md" />}
                h="40px"
                w="40%"
                size={"md"}
                variant="outline"
                onPress={() => navigation.navigate(ROUTES.MY_FAVORITES)}
              >
                Favorilerim
              </Button>
            </HStack>

            <HStack
              alignItems="center"
              space={1}
              style={{
                alignItems: "flex-end",
                alignSelf: "stretch",
                justifyContent: "center",
              }}
            >
              <Button
                leftIcon={<Icon as={AntDesign} name="warning" size="md" />}
                colorScheme="secondary"
                h="40px"
                w="40%"
                size={"md"}
                variant="outline"
                onPress={() => setAccountDeleteIsOpen(!accountDeleteIsOpen)}
              >
                Hesabı Sil
              </Button>
              <Button
                leftIcon={<Icon as={AntDesign} name="logout" size="md" />}
                h="40px"
                w="40%"
                size={"md"}
                variant="outline"
                isLoading={loading}
                onPress={async () => {
                  setLoading(true);
                  await logout();
                  setLoading(false);
                  navigation.navigate(ROUTES.LIST_SCREEN);
                }}
              >
                Çıkış Yap
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </DismissKeyboard>
    </>
  );
};

export default UserProfileScreen;
