import { Box, Image } from "native-base";
import { ScrollView, Pressable } from "react-native";
import { Entypo } from "@expo/vector-icons";
import { dims } from "../constants";
import { forwardRef } from "react";

const Carousel = forwardRef(({ photos, onDelete }, ref) => {
  return (
    <Box style={{ width: Math.min(dims.width - 20, 580) }}>
      <ScrollView
        ref={ref}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
      >
        {photos.map((photo, index) => (
          <Box key={index} style={{ position: "relative" }}>
            <Image
              source={{ uri: photo }}
              key={photo}
              style={{
                height: "100%",
                minHeight: 350,
                resizeMode: "cover",
                width: Math.min(dims.width - 20, 580),
              }}
              alt="Alternate Text"
              size="xl"
            />
            {onDelete ? (
              <Pressable
                onPress={async () => {
                  try {
                    onDelete(index);
                    // setPhotos(photos.filter((photo, idx) => idx !== index));
                    ref.current.scrollTo?.({
                      x: 0,
                    });
                  } catch {}
                }}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 20,
                }}
              >
                <Entypo name="cross" size={30} color="red" />
              </Pressable>
            ) : null}
          </Box>
        ))}
      </ScrollView>
    </Box>
  );
});

export default Carousel;
