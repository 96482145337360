import { Box, Text } from "native-base";
import { Entypo } from "@expo/vector-icons";

export default function EmptyView() {
  return (
    <Box
      width={"100%"}
      height="100%"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Entypo name="images" size={50} />
      <Text>Ürün Resim Alanı</Text>
    </Box>
  );
}
