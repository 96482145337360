import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Pressable,
  Text,
} from "native-base";
import {
  MaterialCommunityIcons,
  AntDesign,
  FontAwesome,
} from "@expo/vector-icons";
import { useProduct } from "../../../services/product";
import { useUser } from "../../../services/auth";
import { ROUTES } from "../../../constants";

export default function Footer({
  selected,
  setSelected,
  navigate,
  setShowModal,
}) {
  const { setInfoService, setSelectedService, setTerm } = useProduct();
  const { user } = useUser();

  return (
    <Box width="100%" zIndex={100}>
      <HStack bg="primary.500" alignItems="center" safeAreaBottom shadow={6}>
        <Pressable
          cursor="pointer"
          opacity={selected === 0 ? 1 : 0.5}
          py="3"
          flex={1}
          onPress={() => {
            setSelected(0);
            setSelectedService("sale");
            setTerm("");
          }}
        >
          <Center>
            <Icon
              mb="1"
              as={
                <MaterialCommunityIcons
                  name={selected === 0 ? "home" : "home-outline"}
                />
              }
              color="white"
              size="sm"
            />
            <Text color="white" fontSize="12">
              Satılık
            </Text>
          </Center>
        </Pressable>
        <Pressable
          cursor="pointer"
          opacity={selected === 1 ? 1 : 0.5}
          py="2"
          flex={1}
          onPress={() => {
            setSelected(1);
            setSelectedService("rent");
            setTerm("");
          }}
        >
          <Center>
            <Icon
              mb="1"
              as={<FontAwesome name="shopping-bag" />}
              color="white"
              size="sm"
            />

            <Text color="white" fontSize="12">
              Kiralık
            </Text>
          </Center>
        </Pressable>
        <IconButton
          backgroundColor={"gray.200"}
          variant={"solid"}
          borderRadius="full"
          size="70"
          borderColor={"primary.500"}
          borderWidth={8}
          mt="-13"
          _icon={{
            as: AntDesign,
            name: "plus",
            color: "primary.500",
          }}
          onPress={() => {
            if (!user?.uid) {
              navigate(ROUTES.SIGN_IN);
              return;
            }
            navigate(ROUTES.CREATE_SCREEN);
          }}
        />
        <Pressable
          cursor="pointer"
          opacity={0.5}
          py="2"
          flex={1}
          onPress={() => {
            if (!user?.uid) {
              navigate(ROUTES.SIGN_IN);
              return;
            }
            setInfoService({
              title: "Teknik Servis",
              body: "body",
              id: "teknik",
            });
            setShowModal(true);
          }}
        >
          <Center>
            <Icon
              mb="1"
              as={
                <FontAwesome
                  name="microchip"
                />
              }
              color="white"
              size="sm"
            />
            <Text color="white" fontSize="12">
              Teknik
            </Text>
          </Center>
        </Pressable>
        <Pressable
          cursor="pointer"
          opacity={0.5}
          py="2"
          flex={1}
          onPress={() => {
            if (!user?.uid) {
              navigate(ROUTES.SIGN_IN);
              return;
            }
            setInfoService({
              title: "Sigorta Servisi",
              body: "body",
              id: "sigorta",
            });
            setShowModal(true);
          }}
        >
          <Center>
            <Icon
              mb="1"
              as={
                <FontAwesome
                  name="hand-paper-o"
                />
              }
              color="white"
              size="sm"
            />
            <Text color="white" fontSize="12">
              Sigorta
            </Text>
          </Center>
        </Pressable>
      </HStack>
    </Box>
  );
}
