import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Pressable,
  StatusBar,
  Text,
} from "native-base";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useUser } from "../services/auth";
import { ADMIN_UID, ROUTES } from "../constants";
import { useProduct } from "../services/product";
import { useNavigation } from "@react-navigation/native";
import { truncate } from "../utils";
import { memo } from "react";

export function Header({
  backButtonPressed,
  title = "Hepsicihaz",
  navigate,
  authScreen = false,
}) {
  const { user } = useUser();
  const { refresh } = useProduct();
  const isAdmin = user?.uid === ADMIN_UID;

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box safeAreaTop bg="primary.500" />
      <Box
        px="4"
        pt="4"
        pb="5"
        bg="primary.500"
        borderColor="coolGray.200"
        overflow={"hidden"}
      >
        <HStack
          space="2"
          justifyContent="space-between"
          w="100%"
          maxW={"600px"}
          marginX="auto"
        >
          <HStack space="2" alignItems="center">
            {backButtonPressed && (
              <IconButton
                variant="ghost"
                colorScheme="light"
                onPress={backButtonPressed}
                icon={
                  <Icon
                    size="sm"
                    as={AntDesign}
                    name="arrowleft"
                    color="coolGray.50"
                  />
                }
              />
            )}

            {title && (
              <Text color="coolGray.50" fontSize="md">
                {truncate(title, 25)}
              </Text>
            )}
          </HStack>

          {
            <HStack space="2" alignItems="center">
              {user.uid && isAdmin ? (
                <Button
                  leftIcon={<Icon as={AntDesign} name="lock" size="sm" />}
                  onPress={() => navigate(ROUTES.ADMIN_SCREEN)}
                  _text={{ color: "gray.100", fontSize: "sm" }}
                >
                  Admin
                </Button>
              ) : null}

              {user.uid ? (
                <Button
                  leftIcon={<Icon as={AntDesign} name="user" size="sm" />}
                  onPress={() => navigate(ROUTES.USER_PROFILE)}
                  _text={{ color: "gray.100", fontSize: "sm" }}
                >
                  Hesabım
                </Button>
              ) : null}

              {!authScreen && !user.uid ? (
                <Button
                  leftIcon={<Icon as={AntDesign} name="login" size="sm" />}
                  onPress={() => navigate(ROUTES.SIGN_IN)}
                  _text={{ color: "gray.100", fontSize: "sm" }}
                >
                  Giriş Yap
                </Button>
              ) : null}

              {!authScreen && !user.uid ? (
                <Button
                  leftIcon={<Icon as={AntDesign} name="plus" size="sm" />}
                  onPress={() => navigate(ROUTES.SIGN_UP)}
                  _text={{ color: "gray.100", fontSize: "sm" }}
                >
                  Üye Ol
                </Button>
              ) : null}
            </HStack>
          }
        </HStack>
      </Box>
    </>
  );
}
