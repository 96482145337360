import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { isDev } from './constants';

let devConfig = {
  apiKey: "AIzaSyA0Jo-eLdsBuW25UEv0fPXh32PMVXPDW1g",
  authDomain: "oxem-dev.firebaseapp.com",
  projectId: "oxem-dev",
  storageBucket: "oxem-dev.appspot.com",
  messagingSenderId: "994119799503",
  appId: "1:994119799503:web:d82dcf8b625e1cb74d1dba"
}

const prodConfig = {
  apiKey: "AIzaSyDMLdroIU-xWN8k7uOZVV5g83JJBFV7r4c",
  authDomain: "oxem-6c1e1.firebaseapp.com",
  projectId: "oxem-6c1e1",
  storageBucket: "oxem-6c1e1.appspot.com",
  messagingSenderId: "106588004148",
  appId: "1:106588004148:web:b572adb5530cfd14b6cf61",
  measurementId: "G-EB99V9Z8M4"
}

const firebaseConfig = isDev ? devConfig : prodConfig;

if (firebase.apps.length === 0) {
  console.log('firebase initialize app');
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const toDate = (date) => firebase.firestore.Timestamp.fromDate(date).toDate();

