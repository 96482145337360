import React from "react";
import { TouchableOpacity, StyleSheet, Text } from "react-native";
import { Avatar, Button, FlatList, VStack } from "native-base";

const Categories = ({ data, onPressCategory, selected }) => {
  return (
    <Button.Group space="2" mt={3} alignItems="center">
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        data={data}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => {
          const isSelected =
            selected?.toLowerCase() === item.value.toLowerCase();

          return (
            <Button
              py="2"
              px="2"
              mr="1"
              variant="subtle"
              size={"sm"}
              onPress={() => onPressCategory(item.value)}
              _text={{
                color: isSelected ? "black" : "white",
                fontWeight: "normal",
              }} //@ts-ignore
              _light={{
                colorScheme: "primary",
              }}
              bg={isSelected ? "accent.500" : "primary.500"}
            >
              {item?.label || " "}
            </Button>
          );
        }}
      />
    </Button.Group>
  );
};

const styles = StyleSheet.create({
  container: {
    marginRight: 30,
    marginTop: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  imageStyle: {
    width: 250,
    height: 120,
    borderRadius: 100,
    marginBottom: 5,
    resizeMode: "contain",
  },
  name: {
    fontSize: 10,
    marginTop: 5,
  },
});

export default Categories;
