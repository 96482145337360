import { Box, Spinner } from "native-base";

export default function Loader() {
  return (
    <Box
      bg="gray.100"
      style={{
        width: "100%",
        // height: "100%",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      <Spinner
        accessibilityLabel="Loading posts"
        color="primary.600"
        size="lg"
      />
    </Box>
  );
}
