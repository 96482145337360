import { useEffect } from "react";
import create from "zustand";
import { db, serverTimestamp } from "../firebaseDb";
import { ADMIN_UID } from "../constants";

export const useRequestStore = create((set) => ({
  requests: null,
  setRequests: (requests) => set(() => ({ requests })),
}));

export function useRequest() {
  const {
    requests,
    setRequests,
  } = useRequestStore();

  useEffect(() => {
    getRequests()
  }, [])

  async function getRequestsAndSetCursor(collection) {
    const arr = [];
    await collection.get().then((snap) => {
      snap.forEach((doc) => {
        const data = doc.data();
        const request = {
          id: doc.id,
          ...data,
          createdAt: data.createdAt.toDate().getTime(),
          completedAt: data.completedAt ? data.completedAt.toDate().getTime() : null,
        }
        arr.push(request);
      });
    })
    return arr
  }

  async function getRequests() {
    const collection = db.collection("userRequests")
      .orderBy("isCompleted", "asc")
      .orderBy("createdAt", "desc");
    const arr = await getRequestsAndSetCursor(collection)
    setRequests(arr);
    return arr;
  }

  async function refresh() {
    await getRequests();
  }

  async function createProductRequest({ userId, productId }) {
    try {
      const collection = db.collection("userRequests");
      const now = serverTimestamp;
      const item = await collection.add({
        userId: userId,
        productId: productId,
        createdAt: now,
        type: "product",
        isCompleted: false
      });
      await refresh();
    } catch (error) {
      console.log("error @createProductRequest");
      return error;
    }
  }

  async function createOtherRequest({ userId, type, description }) {
    try {
      const collection = db.collection("userRequests");
      const now = serverTimestamp;
      const item = await collection.add({
        userId: userId,
        createdAt: now,
        type: type,
        description: description,
        isCompleted: false
      });
      await refresh();
    } catch (error) {
      console.log("error @createOtherRequest");
      return error;
    }
  }

  async function completeRequest(requestId, userId) {
    if (userId === ADMIN_UID) {
      try {
        const doc = db.collection("userRequests").doc(requestId);
        const now = serverTimestamp;
        await doc.update({ isCompleted: true, completedAt: now });
        await refresh();
      } catch (error) {
        console.log(error)
      }
    }
  }

  return {
    requests,
    getRequests,
    refresh,
    createProductRequest,
    createOtherRequest,
    completeRequest
  };
}
