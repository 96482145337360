import React, { useEffect, useState } from "react";
import {
  VStack,
  Box,
  Center,
  StatusBar,
  Stack,
  Heading,
  FlatList,
  ScrollView,
  HStack,
  Icon,
  Text,
} from "native-base";
import SearchBar from "../ListingScreen/components/SearchBar";
import ProductCard from "../ListingScreen/components/ProductCard";
import { AntDesign } from "@expo/vector-icons";
import { ROUTES } from "../../constants";
import { Header } from "../../components/Header";
import { useProduct } from "../../services/product";
import { useUser } from "../../services/auth";
import NotFound from "../ListingScreen/components/NotFound";
import Loader from "../ListingScreen/components/Loader";

const UserListingPage = ({ navigation }) => {
  const [term, setTerm] = useState("");
  const { getProductsByOwnerId } = useProduct();
  const [data, setData] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    setData(getProductsByOwnerId(user.uid) || []);
  }, []);

  const searchProducts = () => {
    let tempArray = data.filter(
      (item) =>
        item.category.toLowerCase() === term.toLowerCase() ||
        item.title.toLowerCase().search(term.toLowerCase()) !== -1 ||
        item.description.search(term) !== -1
    );
    setData(tempArray);
  };

  return (
    <>
      <Header
        title="Ürünlerim"
        navigate={navigation.navigate}
        backButtonPressed={() => navigation.navigate(ROUTES.LIST_SCREEN)}
      />
      {data ? (
        <Box px="2" py="2" flex="1" w="100%" maxW={"600px"} marginX="auto">
          {data.length > 0 ? (
            <FlatList
              data={data}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) => (
                <ProductCard
                  item={item}
                  onPressCheck={() => {
                    navigation.navigate(ROUTES.CREATE_SCREEN, {
                      product: item,
                      isMyProducts: true,
                    });
                  }}
                />
              )}
            />
          ) : (
            <NotFound />
          )}
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserListingPage;
