import React, { memo, useEffect, useState } from "react";
import { Box } from "native-base";
import { useProduct } from "../../services/product";
import { auth } from "../../firebaseDb";

import { Header } from "../../components/Header";
import CustomModal from "../../components/Modal";
import { useUser } from "../../services/auth";

import List from "./List";
import ListMenu from "./components/ListMenu";
import Loader from "./components/Loader";
import NotFound from "./components/NotFound";
import Footer from "./components/Footer";
import { isWeb, ROUTES } from "../../constants";

const ListingScreen = memo(({ navigation }) => {
  const { getProducts, filteredProducts } = useProduct();
  const { user } = useUser();

  useEffect(async () => {
    const items = await getProducts();
    if (!isWeb) return;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    if (!id) return;
    const item = items.find((item) => item.id === id);
    if (!item) return;
    window.history.pushState({}, document.title, window.location.pathname);
    navigation.navigate(ROUTES.PRODUCT_SCREEN, { product: item });
  }, []);

  useEffect(() => {
    try {
      for (let i = 0; i < filteredProducts.length; i++) {
        Image.prefetch(filteredProducts[i].photos[0]);
      }
    } catch {}
  }, [filteredProducts]);

  if (!filteredProducts) {
    return <Loader />;
  }

  return (
    <Box px="2" py="2" flex="1" w="100%" maxW={"600px"} marginX="auto">
      {filteredProducts.length > 0 ? (
        <List
          user={user}
          navigate={navigation.navigate}
          filteredProducts={filteredProducts}
        />
      ) : (
        <NotFound />
      )}
    </Box>
  );
});

export default function ListingScreenWrapper(props) {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(0);
  //  const title = selected === 0 ? "Satılık" : "Kiralık";
  const title = "Ürünler";

  return (
    <>
      <CustomModal showModal={showModal} setShowModal={setShowModal} />
      <Header title={`${title}`} navigate={props.navigation.navigate} />
      <ListMenu navigate={props.navigation.navigate} />
      <ListingScreen {...props} />
      <Footer
        selected={selected}
        setSelected={setSelected}
        navigate={props.navigation.navigate}
        setShowModal={setShowModal}
      />
    </>
  );
}
