import React from "react";
import { Box, Input, Button, Text, IconButton } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

const SearchBar = ({ term, onTermChange, onSubmit }) => {
  return (
    <Box
      alignItems="center"
      flexDirection={"row"}
      justifyContent="space-between"
      pb="2"
      px="2"
    >
      <Input
        type="text"
        w="100%"
        h="45px"
        py="0"
        borderColor={"primary.500"}
        _focus={{
          backgroundColor: "gray.100",
        }}
        bg="gray.100"
        InputRightElement={
          <IconButton
            onPress={onSubmit}
            _icon={{
              as: MaterialIcons,
              name: "search",
              color: "primary.500",
            }}
          />
        }
        placeholder="Ürün veya kategori ara..."
        onChangeText={onTermChange}
        onEndEditing={onSubmit}
        autoCapitalize="none"
        autoCorrect={false}
      />
    </Box>
  );
};

export default SearchBar;
