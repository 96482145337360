import {
  Box,
  Icon,
  Button,
  Checkbox,
  ScrollView,
  Spinner,
  Text,
  HStack,
  VStack,
} from "native-base";

import { AntDesign } from "@expo/vector-icons";
import { useEffect, useState } from "react";
import { ROUTES, dims } from "../../constants";
import { useUser } from "../../services/auth";
import { useProduct } from "../../services/product";
import { useRequest } from "../../services/request";
import { Header } from "../../components/Header";

export function AdminScreen(props) {
  const { user, users, getUsers } = useUser();
  const { products, getProducts } = useProduct();
  const { requests, getRequests, completeRequest } = useRequest();
  const [loading, setLoading] = useState(false);
  const [serviceInquiry, setServiceInquiry] = useState("allProducts");

  useEffect(() => {
    getUsers();
    getProducts();
    getRequests();
  }, []);

  return (
    <>
      <Header
        title={"Admin"}
        navigate={props.navigation.navigate}
        backButtonPressed={() => {
          props.navigation.navigate(ROUTES.LIST_SCREEN);
        }}
      />
      <VStack
        my="auto"
        flex="1"
        w="100%"
        maxW={"600px"}
        marginX="auto"
        space={4}
        alignItems="center"
        bg="white"
        h={dims.height - 120}
      >
        <Box
          style={{
            display: "flex",
            width: "100%",
          }}
          padding="4"
          pb="0"
          pl="5"
        >
          <Box
            w="100%"
            flexDir="row"
            justifyContent={"space-between"}
            my="1"
          >
            <VStack
              w="100%"
              space={1}
              alignItems="center"
              style={{
                alignItems: "flex-end",
                alignSelf: "stretch",
                justifyContent: "center",
              }}
            >
              <HStack
                alignItems="center"
                space={1}
                style={{
                  alignItems: "flex-end",
                  alignSelf: "stretch",
                  justifyContent: "center",
                }}
              >
                <Button
                  h="40px"
                  w="50%"
                  size={"sm"}
                  variant={serviceInquiry === "allProducts" ? undefined : "outline"}
                  onPress={() => setServiceInquiry("allProducts")}
                >
                  Ürün Listesi
                </Button>
                <Button
                  h="40px"
                  w="50%"
                  size={"sm"}
                  variant={serviceInquiry === "unapprovedProducts" ? undefined : "outline"}
                  onPress={() => setServiceInquiry("unapprovedProducts")}
                >
                  Onay Bekleyen Ürünler
                </Button>
              </HStack>
              <HStack
                alignItems="center"
                space={1}
                style={{
                  alignItems: "flex-end",
                  alignSelf: "stretch",
                  justifyContent: "center",
                }}
              >
                <Button
                  h="40px"
                  w="50%"
                  size={"sm"}
                  variant={serviceInquiry === "productRequests" ? undefined : "outline"}
                  onPress={() => setServiceInquiry("productRequests")}
                >
                  Ürün Talepleri
                </Button>
                <Button
                  h="40px"
                  w="50%"
                  size={"sm"}
                  variant={serviceInquiry === "otherRequests" ? undefined : "outline"}
                  onPress={() => setServiceInquiry("otherRequests")}
                >
                  Diğer Talepler
                </Button>
              </HStack>
            </VStack>
          </Box>
        </Box>
        <ScrollView w="100%" padding="4">
          {serviceInquiry === "allProducts" ? (
            <VStack>
              {(products || []).map((product) => {
                const owner = (users || []).find(
                  (u) => u.uid === product.ownerUid
                );
                return (
                  <Box
                    key={product.id}
                    padding="4"
                    marginBottom="4"
                    bg={product.isDeleted ? "red.300" : "gray.300"}
                    shadow={2}
                    borderRadius="8"
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Ürün Sahibi:</Text>{" "}
                      {owner.name} {owner.lastName}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>E-posta:</Text>{" "}
                      {owner.email}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Telefon:</Text>{" "}
                      {owner.phone}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Ürün:</Text>{" "}
                      {product.title}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Kategori:</Text>{" "}
                      {product.category}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Ürün Tipi:</Text>{" "}
                      {product.saleType == "sale" ? "Satılık" : "Kiralık"}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Fiyat:</Text>{" "}
                      {new Intl.NumberFormat("tr-TR", {
                          style: "currency",
                          currency: "TRY",
                          minimumFractionDigits: 0,
                      }).format(product.price)}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Güncellenme Tarihi:</Text>{" "}
                      {new Intl.DateTimeFormat("tr-TR", {
                        day: "2-digit",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      }).format(product.updatedAt)}
                    </Text>
                    <Button
                      leftIcon={<Icon as={AntDesign} name="link" size="sm" />}
                      marginTop={"3"}
                      _text={{ fontSize: "sm", fontWeight: "semibold" }}
                      onPress={() => {
                        product.isDeleted 
                        ? props.navigation.navigate(
                            ROUTES.PRODUCT_SCREEN, { product }
                            )
                        : props.navigation.navigate(
                            ROUTES.CREATE_SCREEN, { product }
                            )
                      }}
                    >
                      Ürün Detayı
                    </Button>
                  </Box>
                );
              })}
            </VStack>
          ) : null}

          {serviceInquiry === "unapprovedProducts" ? (
            <VStack>
              {(products || [])
                .filter((p) => !p.isApproved && !p.isDeleted)
                .map((unapprovedProduct) => {
                const owner = (users || []).find(
                  (u) => u.uid === unapprovedProduct.ownerUid
                );

                return (
                  <Box
                    key={unapprovedProduct.id}
                    padding="4"
                    marginBottom="4"
                    bg="gray.300"
                    shadow={2}
                    borderRadius="8"
                  >
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Ürün Sahibi:</Text>{" "}
                      {owner.name} {owner.lastName}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>E-posta:</Text>{" "}
                      {owner.email}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Telefon:</Text>{" "}
                      {owner.phone}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Ürün:</Text>{" "}
                      {unapprovedProduct.title}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Kategori:</Text>{" "}
                      {unapprovedProduct.category}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Ürün Tipi:</Text>{" "}
                      {unapprovedProduct.saleType == "sale" ? "Satılık" : "Kiralık"}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Fiyat:</Text>{" "}
                      {new Intl.NumberFormat("tr-TR", {
                          style: "currency",
                          currency: "TRY",
                          minimumFractionDigits: 0,
                      }).format(unapprovedProduct.price)}
                    </Text>
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Güncellenme Tarihi:</Text>{" "}
                      {new Intl.DateTimeFormat("tr-TR", {
                        day: "2-digit",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      }).format(unapprovedProduct.updatedAt)}
                    </Text>
                    <Button
                      leftIcon={<Icon as={AntDesign} name="link" size="sm" />}
                      marginTop={"3"}
                      _text={{ fontSize: "sm", fontWeight: "semibold" }}
                      onPress={() => {
                        props.navigation.navigate(ROUTES.CREATE_SCREEN, {
                          product: unapprovedProduct,
                        });
                      }}
                    >
                      Ürün Detayı
                    </Button>
                  </Box>
                );
              })}
            </VStack>                        
          ) : null}

          {serviceInquiry === "productRequests" ? (
            <VStack>
              {(requests || [])
                .filter((request) => request.type == "product")
                .map((request) => {
                  const owner = (users || []).find(
                    (u) => u.uid === request.userId
                  );
                  const product = (products || []).find(
                    (product) => product.id === request.productId
                  );
    
                  const productOwner = (users || []).find(
                    (u) => u?.uid === product?.ownerUid
                  );
                  if (!owner || !product || !productOwner) {
                    console.log("owner || product || productOwner is null", {
                      owner,
                      product,
                      productOwner,
                    });
                    return null;
                  }

                  return (
                    <Box
                      key={request.id}
                      padding="4"
                      marginBottom="4"
                      bg={request.isDeleted ? "red.300" : (request.isCompleted ? "green.300" : "gray.300")}
                      shadow={2}
                      borderRadius="8"
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Talep Sahibi:</Text>{" "}
                        {owner.name} {owner.lastName}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>E-posta:</Text>{" "}
                        {owner.email}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Telefon:</Text>{" "}
                        {owner.phone}
                      </Text>
                      <Text>
                      <Text style={{ fontWeight: "bold" }}>Talep Tarihi:</Text>{" "}
                      {new Intl.DateTimeFormat("tr-TR", {
                        day: "2-digit",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      }).format(request.createdAt)}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Ürün Sahibi:</Text>{" "}
                        {productOwner.name} {productOwner.lastName}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>E-posta:</Text>{" "}
                        {productOwner.email}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Telefon:</Text>{" "}
                        {productOwner.phone}
                      </Text>
                      {!request.isDeleted
                        ?
                          !request.isCompleted
                            ?
                              <Box flexDirection={"row"}>
                                <Text style={{ fontWeight: "bold" }}>Görüşme Sağlandı:</Text>{" "}
                                <Checkbox
                                  id="productrequest-checkbox"
                                  onChange={async () => {
                                    setLoading(true);
                                    await completeRequest(request.id, user.uid);
                                    setLoading(false);
                                  }}
                                />
                              </Box>
                            :
                              <Text>
                                <Text style={{ fontWeight: "bold" }}>İletişim Tarihi:</Text>{" "}
                                {new Intl.DateTimeFormat("tr-TR", {
                                  day: "2-digit",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric"
                                }).format(request.completedAt)}
                              </Text>
                        : null
                      }
                      <Button
                        leftIcon={<Icon as={AntDesign} name="link" size="sm" />}
                        marginTop={"3"}
                        _text={{ fontSize: "sm", fontWeight: "semibold" }}
                        onPress={() => {
                          props.navigation.navigate(
                            ROUTES.PRODUCT_SCREEN, { product }
                          )
                        }}
                      >
                        Ürün Detayı
                      </Button>
                    </Box>
                  );
                })}
            </VStack>
          ) : null}

          {serviceInquiry === "otherRequests" ? (
            <VStack>
              {(requests || [])
                .filter((request) => request.type != "product")
                .map((request) => {
                  const owner = (users || []).find(
                    (u) => u.uid === request.userId
                  );
                  return (
                    <Box
                      key={request.id}
                      padding="4"
                      marginBottom="4"
                      bg={request.isDeleted ? "red.300" : (request.isCompleted ? "green.300" : "gray.300")}
                      shadow={2}
                      borderRadius="8"
                    >
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>{request.type == "feedback" ? "Geri Bildirim" : (request.type == "sigorta" ? "Sigorta" : "Teknik")} Talebi</Text>                        
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Talep Sahibi:</Text>{" "}
                        {owner.name} {owner.lastName}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>E-posta:</Text>{" "}
                        {owner.email}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Telefon:</Text>{" "}
                        {owner.phone}
                      </Text>
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Açıklama:</Text>{" "}
                        {request.description}
                      </Text>
                      <Text>
                      <Text style={{ fontWeight: "bold" }}>Talep Tarihi:</Text>{" "}
                      {new Intl.DateTimeFormat("tr-TR", {
                        day: "2-digit",
                        month: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      }).format(request.createdAt)}
                      </Text>
                      {!request.isDeleted
                        ?
                          !request.isCompleted
                            ?
                              <Box flexDirection={"row"}>
                                <Text style={{ fontWeight: "bold" }}>Görüşme Sağlandı:</Text>{" "}
                                <Checkbox
                                  id="productrequest-checkbox"
                                  onChange={async () => {
                                    setLoading(true);
                                    await completeRequest(request.id, user.uid);
                                    setLoading(false);
                                  }}
                                />
                              </Box>
                            :
                              <Text>
                                <Text style={{ fontWeight: "bold" }}>İletişim Tarihi:</Text>{" "}
                                {new Intl.DateTimeFormat("tr-TR", {
                                  day: "2-digit",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric"
                                }).format(request.completedAt)}
                              </Text>
                        : null
                      }
                  </Box>
                  );
                })}
            </VStack>
          ) : null}
        </ScrollView>
      </VStack>
    </>
  );
}
