import { orderBy, sortBy } from 'lodash';
import { useEffect } from 'react';
import create from 'zustand';
import { auth, db } from '../firebaseDb';

export const useCategoryStore = create((set) => ({
  categories: null,
  setCategories: (categories) => set(() => ({ categories })),
}));

export function useCategory() {
  const { categories, setCategories } = useCategoryStore((state) => ({
    setCategories: state.setCategories,
    categories: state.categories,
  }));

  useEffect(() => {
    getCategories()
  }, [])

  async function getCategories() {
    const collection = db.collection("categories");
    let arr = [];
    await collection.get().then((snap) => {
      snap.forEach((doc) => {
        const data = doc.data();
        arr.push({ label: doc.id, value: doc.id, id: doc.id });
      });
    });
    arr = orderBy(arr, [cat => cat.label.toLowerCase()]);
    arr.unshift({ id: 0, value: "Tümü", label: "Tümü" })
    setCategories(arr);
    return arr;
  }

  return {
    categories
  }
}