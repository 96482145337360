import { useEffect, useRef, useState } from "react";
import create from "zustand";
import { auth, db, serverTimestamp, storage, toDate } from "../firebaseDb";
import { base64ToBlob } from "../utils";
import { FETCH_PRODUCT_LIMIT, ADMIN_UID, isWeb } from "../constants";
import { Image } from "react-native";

export const useProductStore = create((set) => ({
  products: null,
  setProducts: (products) => set(() => ({ products })),
  selectedService: "sale",
  setSelectedService: (selectedService) => set(() => ({ selectedService })),
  filteredProducts: null,
  setFilteredProducts: (filteredProducts) => set(() => ({ filteredProducts })),
  term: "",
  setTerm: (term) => set(() => ({ term })),
  infoService: null,
  setInfoService: (infoService) => set(() => ({ infoService })),
}));

export function useProduct() {
  const productSnapRef = useRef()

  const {
    products,
    setProducts,
    selectedService,
    setSelectedService,
    setFilteredProducts,
    filteredProducts,
    term,
    setTerm,
    infoService,
    setInfoService
  } = useProductStore();

  useEffect(() => {
    if (!products) return;
    const service = selectedService || "sale";
    const prods = products?.filter((p) => p.isApproved && !p.isDeleted && p.saleType === service);
    setFilteredProducts(prods);
  }, [products, selectedService]);

  async function getProductsAndSetCursor(collection) {
    const arr = [];
    await collection.get().then((snap) => {
      productSnapRef.current = snap
      snap.forEach((doc) => {
        const data = doc.data();
        const product = {
          id: doc.id,
          ...data,
          createdAt: data.createdAt.toDate().getTime(),
          updatedAt: data.updatedAt.toDate().getTime(),
        }
        if (!product.photos) product.photos = []
        arr.push(product);
      });
    })
    return arr
  }

  async function preFetchImage(arr) {
    try {
      const prefetchArr = []
      for (let i = 0; i < 10; i++) {
        const photoUri = arr?.[i]?.photos?.[0]
        if (!photoUri) return
        prefetchArr.push(Image.prefetch(photoUri));
      }
      await Promise.all(prefetchArr)
    } catch (e) {
      console.log("error @preFetchImage", e)
    }
  }

  async function getProducts() {
    const collection = db.collection("products")
      .orderBy("updatedAt", "desc");
    const arr = await getProductsAndSetCursor(collection)
//    if (!isWeb) {
//      await preFetchImage(arr)
//    }
    setProducts(arr);
    return arr;
  }

  async function refresh() {
    await getProducts();
  }

  async function saveToStorageAndUpdateDocument({ collection, photo, itemId }) {
    if (photo.startsWith("https://")) {
      return photo
    }
    if (!photo.startsWith("https://")) {
      const ref = storage.ref().child(`products/${itemId}-${Date.now()}.jpg`);
      const blob = await base64ToBlob(photo);

      await ref.put(blob);
      const downloadURL = await ref.getDownloadURL();
      return downloadURL
      // if (downloadURL) {
      //   await collection.doc(itemId).update({
      //     photo: downloadURL,
      //   });
      // }
    }
  }

  async function createProduct({
    category,
    description,
    title,
    price,
    ownerUid,
    photos,
    displayPrice,
    saleType
  }) {
    try {
      const collection = db.collection("products");
      const now = serverTimestamp;
      const item = await collection.add({
        category,
        description,
        title,
        price,
        ownerUid,
        displayPrice,
        saleType,
        createdAt: now,
        updatedAt: now,
        isApproved: false
      });
      if (item?.id) {
        const downloadUrls = []
        for (let i = 0; i < photos.length; i++) {
          const url = await saveToStorageAndUpdateDocument({ photo: photos[i], itemId: item.id, collection })
          downloadUrls.push(url)
        }
        await collection.doc(item.id).update({
          photos: downloadUrls,
        });
        await refresh();
      }
    } catch (error) {
      console.log("error @createProduct");
      return error;
    }
  }

  async function updateProduct({
    id,
    category,
    description,
    title,
    price,
    ownerUid,
    photos,
    displayPrice,
    saleType,
    userId
  }) {
    const collection = db.collection("products")
    const doc = collection.doc(id)
    const item = await doc.update({
      category,
      description,
      title,
      price,
      displayPrice,
      saleType,
      updatedAt: serverTimestamp,
      isApproved: userId === ADMIN_UID ? true : false
    });
    const downloadUrls = []
    for (let i = 0; i < photos.length; i++) {
      const url = await saveToStorageAndUpdateDocument({ photo: photos[i], itemId: id, collection })
      downloadUrls.push(url)
    }
    await collection.doc(id).update({
      photos: downloadUrls,
    });
    await refresh();
  }

  async function deleteProduct( productId, userId ) {
    if (userId === ADMIN_UID) {
      try {
        const doc = db.collection("products").doc(productId);
        await doc.update({ isDeleted: true });
        await refresh();
      } catch (error) {
        console.error("Error removing document: ", productId);
      }
    } else {
      try {
        const doc = db.collection("products").doc(productId);
        await doc.update({ isDeleted: true });
        await refresh();
      } catch (error) {
        console.error("Error removing document: ", productId);
      }
    }
  }

  async function approveProduct( productId, userId ) {
    if (userId === ADMIN_UID) {
      try {
        const doc = db.collection("products").doc(productId);
        await doc.update({ isApproved: true });
        await refresh();
      } catch (error) {
        console.log('error @approveProduct')
      }
    }
  }

  function getProductById(id) {
    return products.find((p) => p.id === id);
  }

  function getProductsByOwnerId(ownerUid) {
    return products.filter((p) => !p.isDeleted && p.ownerUid === ownerUid);
  }

  function getFavoriteProductsByUserId(userId) {
    return products.filter((p) => !p.isDeleted && p.isApproved && p.favorites && p.favorites.includes(userId));
  }

  async function addToFavorites({ productId, userId }) {
    try {
      const collection = db.collection("products");
      const doc = collection.doc(productId);
      const data = await doc.get();
      const product = data.data();
      const favorites = product.favorites || [];
      const newFavorites =
        favorites.some(uid => uid === userId)
          ? favorites
          : [...favorites, userId]
      if (favorites != newFavorites) {
        await doc.update({ favorites: newFavorites });
        await refresh();
      }
    } catch (error) {
      console.log('error @addToFavorites')
    }
  }

  async function removeFromFavorites({ productId, userId }) {
    try {
      const collection = db.collection("products");
      const doc = collection.doc(productId);
      const data = await doc.get();
      const product = data.data();
      const favorites = product.favorites || [];
      const newFavorites = favorites.filter(function (user) {return user !== userId})
      if (favorites != newFavorites) {
        await doc.update({ favorites: newFavorites });
        await refresh();
      }
    } catch (error) {
      console.log('error @removeFromFavorites')
    }
  }

  return {
    products,
    getProducts,
    refresh,
    getProductById,
    getProductsByOwnerId,
    getFavoriteProductsByUserId,
    createProduct,
    updateProduct,
    deleteProduct,
    approveProduct,
    addToFavorites,
    removeFromFavorites,
    selectedService,
    setSelectedService,
    filteredProducts,
    setFilteredProducts,
    term,
    setTerm,
    infoService,
    setInfoService
  };
}
