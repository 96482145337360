import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  VStack,
  Button,
  Text,
  FormControl,
  Select,
  CheckIcon,
  Input,
  TextArea,
  HStack,
  Stack,
  Icon,
  InputGroup,
  InputLeftAddon,
  Checkbox,
  Radio,
  Alert,
  IconButton,
} from "native-base";
import { Pressable, TouchableOpacity, ScrollView } from "react-native";
import { Camera } from "expo-camera";
import { CameraType } from "expo-camera/build/Camera.types";
import { Entypo, AntDesign } from "@expo/vector-icons";
import { Header } from "../../components/Header";
import { object, string, ref, number, array, bool } from "yup";
import * as ImagePicker from "expo-image-picker";

import { ADMIN_UID, ROUTES, dims } from "../../constants";
import { useUser } from "../../services/auth";
import { useProduct } from "../../services/product";
import { useCategory } from "../../services/categories";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Carousel from "../../components/Carousel";
import EmptyView from "./EmptyView";

let newItemSchema = object({
  photos: array().of(string()).min(1, "Resim eklemeniz gereklidir."),
  saleType: string().required("Ürün tipi seçmeniz gereklidir."),
  category: string().required("Kategori seçmeniz gereklidir."),
  title: string().required("Cihaz adı girmeniz gereklidir."),
  price: number().required("Fiyat girmeniz gereklidir."),
  displayPrice: bool(),
  description: string().required("Cihaz tanımı girmeniz gereklidir."),
});

const CreateScreen = ({ navigation, route }) => {
  const product = route?.params?.product;
  const { user } = useUser();
  const { createProduct, updateProduct, deleteProduct, approveProduct } = useProduct();
  const { categories = [] } = useCategory();

  const [photos, setPhotos] = useState(product?.photos || []);
  const [saleType, setSaleType] = useState(product?.saleType || "sale");
  const [category, setCategory] = useState(product?.category || "");
  const [title, setTitle] = useState(product?.title || "");
  const [price, setPrice] = useState(product?.price || 0);
  const [displayPrice, setDisplayPrice] = useState(product?.displayPrice || false);
  const [description, setDescription] = useState(product?.description || "");

  const [type, setType] = useState(CameraType.back);
  const cameraRef = useRef();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [approving, setApproving] = useState(false);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  // const [photoIndex, setPhotoIndex] = useState(0);
  const [cameraMode, setCameraMode] = useState(false);
  const scrollRef = useRef();

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    if (!product) {
        setPhotos([]);
        setSaleType("sale");
        setCategory("");
        setTitle("");
        setPrice(0);
        setDescription("");
//      return;
    } else {
      setCategory(product.category);
      setTitle(product.title);
      setDescription(product.description);
      setPhotos(product.photos);
      setPrice(product.price);
      setDisplayPrice(product.displayPrice);
      setSaleType(product.saleType);      
    }
  }, [product]);

  async function onSubmitHandler() {
    const valid = await newItemSchema
      .validate({ photos, saleType, category, title, price, displayPrice, description })
      .catch((err) => {
        setError(err.message);
      });

    if (!valid) return;

    try {
      setLoading(true);
      const obj = {
        category,
        description,
        title,
        price,
        ownerUid: user.uid,
        photos,
        displayPrice,
        saleType
      };
      if (product) {
        await updateProduct({ ...obj, id: product.id, userId: user.uid });
      } else {
        await createProduct(obj);
      }
      setLoading(false);
      navigation.navigate(ROUTES.LIST_SCREEN);
    } catch (error) {
      console.log("err @saving createProduct");
      setLoading(false);
    }
  }

  async function onApproveHandler() {
    setApproving(true);
    await approveProduct(product.id, user.uid);
    setApproving(false);
    navigation.navigate(ROUTES.LIST_SCREEN);
  }

  async function onDeleteHandler() {
    setDeleting(true);
    await deleteProduct(product.id, user.uid);
    setDeleting(false);
    navigation.navigate(ROUTES.LIST_SCREEN);
  }

  return (
    <>
      <Header
        navigate={navigation.navigate}
//        title={route?.params?.product.title}
        title="Ürün"
        backButtonPressed={() => {
          navigation.navigate(
            route?.params?.isMyProducts
              ? ROUTES.MY_LISTINGS
              : ROUTES.LIST_SCREEN
          );
        }}
      />

      <KeyboardAwareScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1 }}
      >
        <VStack flex="1" space="6" p="4" w="100%" maxW={"600px"} marginX="auto">
          <Box
            style={{
              borderStyle: "dotted",
              borderWidth: 1,
              borderRadius: 10,
              borderColor: "#8b5cf6",
              justifyContent: "center",
              height: 350,
              minHeight: 350,
              overflow: "hidden",
            }}
          >
            {photos.length > 0 && !cameraMode ? (
              <Carousel
                ref={scrollRef}
                photos={photos}
                onDelete={(index) => {
                  setPhotos(photos.filter((photo, idx) => idx !== index));
                }}
              />
            ) : null}
            {permission?.granted && cameraMode ? (
              <Camera
                ref={cameraRef}
                type={type}
                style={{
                  alignItems: "center",
                  height: 350,
                  minHeight: 350,
                  justifyContent: "center",
                }}
              />
            ) : null}

            {photos.length === 0 && !cameraMode ? <EmptyView /> : null}
          </Box>

          <Alert
            status="info"
            colorScheme="info"
            style={{ marginBottom: 12, marginTop: -6 }}
          >
            <VStack space={2} flexShrink={1} w="100%">
              <HStack
                flexShrink={1}
                space={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <HStack flexShrink={1} space={2} alignItems="center">
                  <Alert.Icon />
                  <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                    Bu bilgileri girmeyi unutmayın
                  </Text>
                </HStack>
              </HStack>
              <Box
                pl="6"
                _text={{
                  color: "coolGray.600",
                }}
              >
                Marka, model, varsa atım sayısı ve garanti durumunu belirtmeyi unutmayınız. Herhangi bir kalibrasyon veya bakım gerekli mi belirtiniz. Son bakım veya arıza belgesi varsa, fotoğrafını koymanızı tavsiye ederiz.
              </Box>
            </VStack>
          </Alert>

          <Box
            display="flex"
            flex="1"
            flexDirection="row"
            mt="2"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button
              marginTop={"-6"}
              height="55"
              bg="gray.600"
              width={"49%"}
              onPress={async () => {
                try {
                  if (!cameraMode) {
                    setCameraMode(true);
                    return;
                  }

                  const photo = await cameraRef.current.takePictureAsync();
                  setCameraMode(false);
                  setPhotos([...photos, photo?.uri]);
                  setTimeout(() => {
                    try {
                      scrollRef.current.scrollToEnd?.();
                    } catch {}
                  }, 60);
                } catch {}
              }}
            >
              <HStack
                bg="gray.600"
                opacity="0.7"
                width="100%"
                alignItems="center"
                justifyContent="center"
                py="2"
                space="4"
              >
                <Entypo name="camera" size={22} color="white" />
                <Text color="white">
                  {cameraMode ? "Kameradan Yükle" : "Kamerayı Aç"}
                </Text>
              </HStack>
            </Button>
            <Button
              marginTop={"-6"}
              height="55"
              width={"50%"}
              bg="gray.600"
              onPress={async () => {
                setCameraMode(false);
                const result = await ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  allowsEditing: true,
                  aspect: [4, 3],
                  quality: 0,
                });
                if (result.cancelled) return;
                setPhotos([...photos, result?.uri]);
                setTimeout(() => {
                  try {
                    scrollRef.current.scrollToEnd?.();
                  } catch {}
                }, 60);
              }}
            >
              <HStack
                bg="gray.600"
                opacity="0.7"
                width="100%"
                alignItems="center"
                justifyContent="center"
                py="2"
                space="4"
              >
                <Entypo name="folder" size={22} color="white" />
                <Text color="white">Fotoğraflarım</Text>
              </HStack>
            </Button>
          </Box>

          <VStack space={4}>
            <Radio.Group
              name="radio-group"
              flexDirection={"row"}
              value={saleType}
              onChange={(nextValue) => {
                setSaleType(nextValue);
              }}
            >
              <Radio value="sale" my="2">
                <FormControl.Label>Satılık</FormControl.Label>
              </Radio>
              <Box w="5" h="5" />
              <Radio value="rent" my="2">
                <FormControl.Label>Kiralık</FormControl.Label>
              </Radio>
            </Radio.Group>

            <FormControl w="100%" isRequired>
              <FormControl.Label>Kategori</FormControl.Label>
              <Select
                selectedValue={category}
                accessibilityLabel="Kategori seçin"
                placeholder="Kategori seçin"
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />,
                }}
                onValueChange={(itemValue) => {
                  setCategory(itemValue);
                }}
              >
                {categories?.slice(1).map((category) => (
                  <Select.Item
                    key={category.value}
                    label={category.label}
                    value={category.value}
                  />
                ))}
              </Select>
            </FormControl>
            <FormControl w="100%" isRequired>
              <FormControl.Label>Cihaz Adı</FormControl.Label>
              <Input
                placeholder="Cihaz adı girin"
                value={title}
                onChangeText={(text) => setTitle(text)}
              />
            </FormControl>
            <FormControl w="100%" isRequired>
              <FormControl.Label>Cihaz Fiyatı</FormControl.Label>
              <InputGroup>
                <InputLeftAddon children={"₺"} width="10%" />
                <Input
                  placeholder="Cihaz fiyatı girin"
                  value={price}
                  keyboardType="number-pad"
                  width="90%"
                  onChangeText={(value) => setPrice(value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl w="100%">
              <Checkbox
                value={displayPrice}
                defaultIsChecked={displayPrice}
                onChange={() => {
                  setDisplayPrice(!displayPrice);
                }}
              >
                <FormControl.Label>Fiyat Görüntülensin?</FormControl.Label>
              </Checkbox>
            </FormControl>
            <FormControl w="100%" isRequired>
              <FormControl.Label>Cihaz Tanımı</FormControl.Label>
              <TextArea
                placeholder="Cihaz tanımı girin"
                value={description}
                onChangeText={(text) => setDescription(text)}
              />
            </FormControl>
          </VStack>
          {error && (
            <Text fontSize="md" color="red.600">
              {error}
            </Text>
          )}
          <Button
            leftIcon={<Icon as={AntDesign} name="sync" size="md" />}
            width="100%"
            _text={{ fontSize: "md", fontWeight: "semibold" }}
            onPress={onSubmitHandler} 
            isLoading={loading}
          >
              Kaydet
          </Button>
          {product &&
            (user.uid === ADMIN_UID && product.isApproved !== true) && (
              <Button
                leftIcon={<Icon as={AntDesign} name="check" size="md" />}
                width="100%"
                marginTop={"-4"}
                _text={{ fontSize: "md", fontWeight: "semibold" }}
                onPress={onApproveHandler}
                isLoading={approving}
              >
                Onayla
              </Button>
            )}
          {product &&
            (user.uid === ADMIN_UID || user.uid === product.ownerUid) && (
              <Button
                leftIcon={<Icon as={AntDesign} name="delete" size="md" />}
                colorScheme="danger"
                width="100%"
                marginTop={"-4"}
                _text={{ fontSize: "md", fontWeight: "semibold" }}
                onPress={onDeleteHandler}
                isLoading={deleting}
              >
                Sil
              </Button>
            )}
        </VStack>
      </KeyboardAwareScrollView>
    </>
  );
};

export default CreateScreen;
