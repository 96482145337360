import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  Text,
  HStack,
  VStack,
  StatusBar,
  Divider,
  Button,
  Stack,
} from "native-base";

import { AntDesign } from "@expo/vector-icons";
import { ADMIN_UID, ROUTES } from "../../constants";
import { useProduct } from "../../services/product";
import { Header } from "../../components/Header";
import { useUser } from "../../services/auth";
import { useRequest } from "../../services/request";
import Carousel from "../../components/Carousel";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useNavigation } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";

const AddToCartButton = (props) => {
  const { user } = useUser();
  const { addToFavorites, removeFromFavorites, getProductById } = useProduct();
  const { requests, createProductRequest } = useRequest();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const realtimeProduct = getProductById(props.product.id);
  const isDeleted = realtimeProduct?.isDeleted || false;
  const alreadyInterested =
    (requests || []).filter(
      (request) =>
        request.type == "product" &&
        request.userId == user.uid &&
        request.productId == realtimeProduct.id
    ).length > 0 || false;
  const favorites = realtimeProduct?.favorites || [];
  const alreadyAddedToFavorites = favorites.includes(user.uid);
  const { navigate } = useNavigation();

  async function createProductRequestPressed() {
    if (!user.uid) {
      navigate(ROUTES.SIGN_IN);
      return;
    }
    if (!alreadyInterested) {
      setLoading(true);
      await createProductRequest({
        userId: user.uid,
        productId: realtimeProduct.id,
      });
      setLoading(false);
    }
  }
  async function addToFavoritesPressed() {
    if (!user.uid) {
      navigate(ROUTES.SIGN_IN);
      return;
    }
    if (!alreadyAddedToFavorites) {
      setLoading2(true);
      await addToFavorites({
        productId: realtimeProduct.id,
        userId: user.uid,
      });
      setLoading2(false);
    }
  }
  async function removeFromFavoritesPressed() {
    if (!user.uid) {
      navigate(ROUTES.SIGN_IN);
      return;
    }
    if (alreadyAddedToFavorites) {
      setLoading2(true);
      await removeFromFavorites({
        productId: realtimeProduct.id,
        userId: user.uid,
      });
      setLoading2(false);
    }
  }

  return (
    <VStack
      mt="2"
      mb="5"
      padding={"4"}
      space={4}
      display={props.base}
      w="100%"
      maxW={"600px"}
      marginX="auto"
    >
      {!isDeleted && user.uid != ADMIN_UID ? (
        user.uid ? (
          alreadyInterested ? (
            <Button
              leftIcon={<Icon as={AntDesign} name="check" size="md" />}
              flex={1}
              h="100%"
              py={3}
              borderRadius="4"
              bg={"tertiary.600"}
              disabled={true}
              _text={{ fontSize: "md", fontWeight: "semibold" }}
            >
              En kısa sürede iletişime geçeceğiz.
            </Button>
          ) : (
            <Button
              leftIcon={<Icon as={AntDesign} name="question" size="md" />}
              flex={1}
              h="100%"
              py={3}
              borderRadius="4"
              bg={"primary.500"}
              _text={{ fontSize: "md", fontWeight: "semibold" }}
              onPress={createProductRequestPressed}
              isLoading={loading}
            >
              Detaylı Bilgi Al
            </Button>
          )
        ) : (
          <Button
            leftIcon={<Icon as={AntDesign} name="warning" size="md" />}
            flex={1}
            h="100%"
            py={3}
            borderRadius="4"
            bg={"primary.500"}
            _text={{ fontSize: "md", fontWeight: "semibold" }}
            onPress={createProductRequestPressed}
            isLoading={loading}
          >
            Detaylı Bilgi Almak için Giriş Yapın
          </Button>
        )
      ) : null}

      {!isDeleted && user.uid != ADMIN_UID ? (
        user.uid ? (
          alreadyAddedToFavorites ? (
            <Button
              leftIcon={<Icon as={AntDesign} name="close" size="md" />}
              flex={1}
              h="100%"
              py={3}
              borderRadius="4"
              colorScheme="danger"
              _text={{ fontSize: "md", fontWeight: "semibold" }}
              onPress={removeFromFavoritesPressed}
              isLoading={loading2}
            >
              Favorilerden Çıkar
            </Button>
          ) : (
            <Button
              leftIcon={<Icon as={AntDesign} name="heart" size="md" />}
              flex={1}
              h="100%"
              py={3}
              borderRadius="4"
              bg={"primary.500"}
              _text={{ fontSize: "md", fontWeight: "semibold" }}
              onPress={addToFavoritesPressed}
              isLoading={loading2}
            >
              Favorilere Ekle
            </Button>
          )
        ) : (
          <Button
            leftIcon={<Icon as={AntDesign} name="warning" size="md" />}
            flex={1}
            h="100%"
            py={3}
            borderRadius="4"
            bg={"primary.500"}
            _text={{ fontSize: "md", fontWeight: "semibold" }}
            onPress={addToFavoritesPressed}
            isLoading={loading2}
          >
            Favorilere Eklemek için Giriş Yapın
          </Button>
        )
      ) : null}
    </VStack>
  );
};

const ProductScreen = (props) => {
  const product = props?.route?.params?.product;
  const [copiedText, setCopiedText] = useState(null);

  useEffect(() => {
    if (!copiedText) return;

    setTimeout(() => {
      setCopiedText(null);
    }, 1000);
  }, [copiedText]);

  return (
    <>
      <Header
        title="Ürün"
        backButtonPressed={() => props.navigation.navigate(ROUTES.LIST_SCREEN)}
        navigate={props.navigation.navigate}
      />

      <KeyboardAwareScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1 }}
      >
        <VStack flex="1" p="2" w="100%" maxW={"600px"} marginX="auto">
          <Box
            borderRadius={8}
            style={{
              borderStyle: "dotted",
              borderWidth: 1,
              borderColor: "#8b5cf6",
              justifyContent: "center",
              height: 350,
              minHeight: 350,
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            <Carousel photos={product.photos} />
          </Box>
          <Box flex={1} bg="white" padding={4}>
            <VStack space={1}>
              <Text fontSize="md" _light={{ color: "coolGray.800" }}>
                <Text _light={{ color: "primary.500" }}>Ürün:</Text>{" "}
                {product.title}
              </Text>
              <Text fontSize="md" _light={{ color: "coolGray.800" }}>
                <Text _light={{ color: "primary.500" }}>Kategori:</Text>{" "}
                {product.category}
              </Text>
              <Text fontSize="md" _light={{ color: "coolGray.800" }}>
                <Text _light={{ color: "primary.500" }}>Ürün Tipi:</Text>{" "}
                {product.saleType == "sale" ? "Satılık" : "Kiralık"}
              </Text>
              {product.displayPrice ? (
                <Text fontSize="md" _light={{ color: "coolGray.800" }}>
                  <Text _light={{ color: "primary.500" }}>Fiyat:</Text>{" "}
                  {new Intl.NumberFormat("tr-TR", {
                    style: "currency",
                    currency: "TRY",
                    minimumFractionDigits: 0,
                  }).format(product.price)}
                </Text>
              ) : null}
              <Text fontSize="md" _light={{ color: "coolGray.800" }}>
                <Text _light={{ color: "primary.500" }}>
                  Güncellenme Tarihi:
                </Text>{" "}
                {new Intl.DateTimeFormat("tr-TR", {
                  day: "2-digit",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }).format(product.updatedAt)}
              </Text>
            </VStack>
            <HStack mt="8" space="5">
              <Box>
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  letterSpacing="0.4"
                  _light={{
                    color: "primary.500",
                  }}
                >
                  Ürün Özellikleri
                </Text>

                <Box width="100%" py="1">
                  <Divider bg="primary.500" />
                </Box>
              </Box>
            </HStack>
            <Text
              mt="3"
              fontSize="sm"
              lineHeight="lg"
              fontWeight="medium"
              letterSpacing="0.3"
              _light={{ color: "coolGray.800" }}
            >
              {product.description}
            </Text>

            <Button
              variant="link"
              marginTop={8}
              onPress={async () => {
                await Clipboard.setStringAsync(
                  window.location.origin + "?id=" + product.id
                );
                setCopiedText(product.id);
              }}
            >
              {copiedText ? "Kopyalandı" : "Ürün linki kopyala"}
            </Button>
          </Box>
        </VStack>
        <AddToCartButton base="flex" product={product} />
      </KeyboardAwareScrollView>
    </>
  );
};

export default ProductScreen;
