import React, { useState } from "react";
import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
} from "native-base";
import { AntDesign, Entypo } from "@expo/vector-icons";
import IconGoogle from "./components/IconGoogle";
import IconFacebook from "./components/IconFacebook";
import FloatingLabelInput from "./components/FloatingLabelInput";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { auth } from "../../firebaseDb";
import { Header } from "../../components/Header";
import { object, string, ref } from "yup";
import { ROUTES } from "../../constants";

let userSchema = object({
  text: string()
    .email("Lütfen geçerli bir e-posta adresi giriniz")
    .required("E-posta alanı zorunludur"),
  pass: string().required("Şifre alanı zorunludur"),
});

export function SignInForm({ props }) {
  // const router = useRouter(); //use incase of Nextjs
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  async function onPressSignInHandler() {
    const valid = await userSchema.validate({ text, pass }).catch((err) => {
      setError(err.message);
    });
    if (!valid) return;
    setLoading(true);
    await auth
      .signInWithEmailAndPassword(text, pass)
      .then((res) => {
        setLoading(false);
        setText("");
        setPass("");
        setError();
        props.navigation.navigate(ROUTES.LIST_SCREEN);
      })
      .catch((error) => {
        console.log("error @signInWithEmailAndPassword");
        setError(error.message);
        setLoading(false);
      });
  }

  return (


    <KeyboardAwareScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderRadius="2xl"
        w="100%"
        maxW={"600px"}
        marginX="auto"
      >
        <VStack space="7">
          <Hidden till="md">
            <Text fontSize="md" fontWeight="normal">
              Devam etmek için giriş yapın
            </Text>
          </Hidden>
          <VStack>
            <VStack space="3">
              <VStack space="4">
                <FloatingLabelInput
                  isRequired
                  label="E-posta"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  h="40px"
                  defaultValue={text}
                  onChangeText={(txt) => setText(txt)}
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _light={{
                    borderColor: "#c9c9c9",
                  }}
                />
                <FloatingLabelInput
                  isRequired
                  h="40px"
                  type={showPass ? "" : "password"}
                  label="Şifre"
                  borderRadius="4"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  defaultValue={pass}
                  onChangeText={(txt) => setPass(txt)}
                  InputRightElement={
                    <IconButton
                      variant="unstyled"
                      onPress={() => {
                        setShowPass(true);
                      }}
                      icon={
                        <Icon
                          size="4"
                          color="coolGray.400"
                          as={Entypo}
                          name={showPass ? "eye-with-line" : "eye"}
                        />
                      }
                    />
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _light={{
                    borderColor: "#c9c9c9",
                  }}
                />
              </VStack>

              {error && (
                <Text fontSize="xl" color="red.600">
                  {error}
                </Text>
              )}
              <Button
                mt="5"
                size="md"
                borderRadius="4"
                _text={{
                  fontWeight: "medium",
                }}
                _light={{
                  bg: "primary.500",
                }}
                isLoading={loading}
                onPress={() => {
                  onPressSignInHandler();
                  // props.navigation.navigate("OTP");
                }}
              >
                GİRİŞ YAP
              </Button>
              <VStack alignItems="center" justifyContent="center">
                <HStack
                  mb="4"
                  space="1"
                  safeAreaBottom
                  alignItems="center"
                  justifyContent="center"
                  mt="auto"
                >
                  <Text
                    _light={{
                      color: "coolGray.800",
                    }}
                  >
                    Hesabınız yok mu?
                  </Text>
                  <Button
                    variant="ghost"
                    onPress={() => props.navigation.navigate(ROUTES.SIGN_UP)}
                    _text={{
                      fontSize: "sm",
                      fontWeight: "bold",
                      color: "primary.500",
                    }}
                  >
                    Üye Ol
                  </Button>
                </HStack>
                <Button
                  variant="ghost"
                  onPress={() => props.navigation.navigate(ROUTES.LIST_SCREEN)}
                  _text={{
                    fontSize: "sm",
                    fontWeight: "bold",
                    color: "primary.500",
                  }}
                >
                  Ürünleri incelemeye devam et
                </Button>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </KeyboardAwareScrollView>
  );
}
export default function SignIn(props) {
  return (
    <>
      <Center my="auto" bg="primary.500" flex="1">
        <Stack flexDirection="column" w="100%" flex="1">
          <VStack px="4" mt="4" mb="5" space="9">
            <VStack space="2" w="100%" maxW={"600px"} marginX="auto">
              <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                Hoşgeldiniz!
              </Text>
            </VStack>
          </VStack>

          <SignInForm props={props} />
        </Stack>
      </Center>
    </>
  );
}
