import { Dimensions, Platform } from 'react-native';

export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';

export const ROUTES = {
  PRODUCT_SCREEN: "Ürün Detayı | Hepsicihaz",
  LIST_SCREEN: "Ürünler | Hepsicihaz",
  MY_LISTINGS: "Ürünlerim | Hepsicihaz",
  MY_FAVORITES: "Favorilerim | Hepsicihaz",
  CREATE_SCREEN: "Yeni Cihaz | Hepsicihaz",
  SIGN_UP: "Üye Ol | Hepsicihaz",
  SIGN_IN: "Giriş Yap | Hepsicihaz",
  FORGOT_PASSWORD: "Şifremi Unuttum | Hepsicihaz",
  ADMIN_SCREEN: "Admin | Hepsicihaz",
  USER_PROFILE: "Hesabım | Hepsicihaz",
}

export const isDev = isWeb && (window?.location?.origin.startsWith("http://localhost:") || window?.location?.origin.startsWith("https://oxem-dev.web.app"))
//export const isDev = false

export const ADMIN_UID =
  isDev
    ? "7fXI8XoFCvUEtLYn0dAQGFH68Kq2"
//    ? "iI70Ly0leiUoQalmKlgSZmfyuGo1"
    : "znDGp2mr01MXUarTeIyVHypgZq43"

export const ElementsText = {
  AUTOPLAY: 'AutoPlay',
};

export const dims = isWeb
  ? {
    ...Dimensions.get('window'),
    width: window?.innerWidth,
  }
  : Dimensions.get('window');
