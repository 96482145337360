import { FlatList, VStack } from "native-base";
import { ADMIN_UID, ROUTES } from "../../constants";
import ProductCard from "./components/ProductCard";

function List({ filteredProducts, navigate, user }) {
  const renderItem = ({ item, index }) => (
    <VStack>
      <ProductCard
        item={item}
        onPressCheck={(id) => {
          const route =
            item.ownerUid === user.uid || user.uid === ADMIN_UID
              ? ROUTES.CREATE_SCREEN
              : ROUTES.PRODUCT_SCREEN;
          navigate(route, { product: item });
        }}
      />
    </VStack>
  );
  return (
    <FlatList
      data={filteredProducts}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      keyExtractor={(item) => item.id}
      renderItem={renderItem}
    />
  );
}

export default List;
