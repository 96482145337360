import { Keyboard, TouchableWithoutFeedback } from "react-native";
import { isWeb } from "../constants";

const DismissKeyboard = ({ children }) => {
  if (isWeb) {
    return children;
  }
  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      {children}
    </TouchableWithoutFeedback>
  );
};

export default DismissKeyboard;
